import { useRoutes } from "react-router-dom";
import { Suspense, lazy } from "react";
import TopLoader from "../Preload/TopLoader";

// Lazy-loaded components
const General = lazy(() => import("../Layouts/general"));
const Home = lazy(() => import("../GuestPages.js/Home"));
const AboutOverview = lazy(() => import("../GuestPages.js/AboutPages/Overview"));
const Feature = lazy(() => import("../GuestPages.js/Feature"));
const SiteLayouts = lazy(() => import("../GuestPages.js/AboutPages/SiteLayout"));
const Plans = lazy(() => import("../GuestPages.js/AboutPages/Plans"));
const PropertyDetail = lazy(() => import("../GuestPages.js/Properties/Property"));
const Broker = lazy(() => import("../GuestPages.js/Properties/Broker"));
const Contact = lazy(() => import("../GuestPages.js/Contact"));
const HomePage = lazy(() => import("../GuestPages.js/Test"));

export default function Routers() {
    return (
        <Suspense fallback={<TopLoader />}>
            {useRoutes([
                {
                    path: "/",
                    element: <General />,
                    children: [
                        { path: "/", element: <Home /> },
                        { path: "/about/overview", element: <AboutOverview /> },
                        { path: "/features", element: <Feature /> },
                        { path: "/siteLayouts", element: <SiteLayouts /> },
                        { path: "/plans", element: <Plans /> },
                        { path: "/propertyDetail/:id", element: <PropertyDetail /> },
                        { path: "/broker", element: <Broker /> },
                        { path: "/contact", element: <Contact /> },
                        { path: "/test", element: <HomePage /> },
                    ],
                },
            ])}
        </Suspense>
    );
}
