import { configureStore } from "@reduxjs/toolkit";
import contentReducer from "./slices/homeSlice"; 
import featureReducer from "./slices/featureSlice"
import contactReducer from "./slices/contactSlice"
const store = configureStore({
  reducer: {
    content: contentReducer, 
    feature: featureReducer,
    contact: contactReducer,
  },
  
});

export default store;
