// src/redux/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching Home data
export const fetchFeatures = createAsyncThunk(
  "content/fetchFeatures",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/feature-offer");
      return response.data;
    } catch (error) {
      console.error("Error fetching Home data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching Home data");
    }
  }
);

export const fetchRealators = createAsyncThunk(
  "content/fetchRealators",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/realtor");
      return response.data;
    } catch (error) {
      console.error("Error fetching Home data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching Home data");
    }
  }
);

// Async thunk for fetching Home data
export const fetchGeneral = createAsyncThunk(
  "content/fetchGeneral",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/general-settings");
      return response.data;
    } catch (error) {
      console.error("Error fetching Home data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching Home data");
    }
  }
);


const featureSlice = createSlice({
  name: "features",
  initialState: {
    features: null,
    relators: null,
    general: null,
    realatorLoading: false,
   loading: false,
   generalLoading: false,
   error: null,
  },
  reducers: {}, // No synchronous reducers
  extraReducers: (builder) => {
    builder
      // Fetch Home Data
      .addCase(fetchFeatures.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.features = action.payload;
      })
      .addCase(fetchFeatures.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

      builder
      // Fetch Home Data
      .addCase(fetchRealators.pending, (state) => {
        state.realatorLoading = true;
        state.error = null;
      })
      .addCase(fetchRealators.fulfilled, (state, action) => {
        state.realatorLoading = false;
        state.relators = action.payload;
      })
      .addCase(fetchRealators.rejected, (state, action) => {
        state.realatorLoading = false;
        state.error = action.payload;
      })
      builder
      // Fetch Home Data
      .addCase(fetchGeneral.pending, (state) => {
        state.generalLoading = true;
        state.error = null;
      })
      .addCase(fetchGeneral.fulfilled, (state, action) => {
        state.generalLoading = false;
        state.general = action.payload;
      })
      .addCase(fetchGeneral.rejected, (state, action) => {
        state.generalLoading = false;
        state.error = action.payload;
      })
      
  },
});

export default featureSlice.reducer;
