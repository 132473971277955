// src/redux/userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching Home data
export const fetchHomeObject = createAsyncThunk(
  "content/fetchHomeObject",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/home-page");
      return response.data;
    } catch (error) {
      console.error("Error fetching Home data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching Home data");
    }
  }
);

// Async thunk for fetching About data
export const fetchAboutObject = createAsyncThunk(
  "content/fetchAboutObject",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/about-us");
      return response.data;
    } catch (error) {
      console.error("Error fetching About data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching About data");
    }
  }
);

export const fetchPlanLayout = createAsyncThunk(
  "content/fetchPlanLayout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("https://admin.theluxatjelb.com/api/request/layout-and-plan");
      return response.data;
    } catch (error) {
      console.error("Error fetching About data:", error);
      return rejectWithValue(error.response?.data || "An error occurred while fetching About data");
    }
  }
);

const contentSlice = createSlice({
  name: "content",
  initialState: {
    homeObject: null,
    aboutObject: null,
    planObject: null,
    homeLoading: false,
    aboutLoading: false,
    planLoading: false,
    homeError: null,
    planError: null,
    aboutError: null,
  },
  reducers: {}, // No synchronous reducers
  extraReducers: (builder) => {
    builder
      // Fetch Home Data
      .addCase(fetchHomeObject.pending, (state) => {
        state.homeLoading = true;
        state.homeError = null;
      })
      .addCase(fetchHomeObject.fulfilled, (state, action) => {
        state.homeLoading = false;
        state.homeObject = action.payload;
      })
      .addCase(fetchHomeObject.rejected, (state, action) => {
        state.homeLoading = false;
        state.homeError = action.payload;
      })
      // Fetch About Data
      .addCase(fetchAboutObject.pending, (state) => {
        state.aboutLoading = true;
        state.aboutError = null;
      })
      .addCase(fetchAboutObject.fulfilled, (state, action) => {
        state.aboutLoading = false;
        state.aboutObject = action.payload;
      })
      .addCase(fetchAboutObject.rejected, (state, action) => {
        state.aboutLoading = false;
        state.aboutError = action.payload;
      })

      // Fetch LAYOUT
      .addCase(fetchPlanLayout.pending, (state) => {
        state.planLoading = true;
        state.planError = null;
      })
      .addCase(fetchPlanLayout.fulfilled, (state, action) => {
        state.planLoading = false;
        state.planObject = action.payload;
      })
      .addCase(fetchPlanLayout.rejected, (state, action) => {
        state.planLoading = false;
        state.planError = action.payload;
      });
  },
});

export default contentSlice.reducer;
